/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useState, useEffect } from 'react'
import connect from '@h/connect-redux'

import { DropDown } from '@uswitch/trustyle.drop-down'
import Button from '@e/button-default'

import { setTheme } from '@r/app-actions'

const UNSET = 'unset'

export default connect()(({ dispatch }) => {
  const match = window.location.search.match(/debug-tools=([\w+-_]+)/)
  const matchThemeId = match ? match[1] : UNSET

  const [themes, setThemes] = useState([])
  const [themeId, setThemeId] = useState(matchThemeId)

  useEffect(() => {
    const fetchThemes = async () => {
      const themesRes = await global.fetch('/themes')
      const themes = await themesRes.json()

      setThemes(themes.map(t => ({ value: t, text: t })))

      if (themeId === UNSET) return

      const themeRes = await global.fetch(`/theme/${themeId}`)
      const theme = await themeRes.json()

      dispatch(setTheme(theme))
    }
    fetchThemes()
  }, [dispatch, themeId])

  return (
    <div
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'light-3',
        backgroundColor: 'white',
        zIndex: '199000',
        textAlign: 'center',
        position: 'fixed',
        p: 'md',
        borderRadius: 'md',
        boxShadow: 'box',
        left: 'md',
        top: 'md',
        opacity: 0.1,
        transition: 'opacity 300ms',
        ':hover': {
          opacity: 0.8,
        },
      }}
    >
      <DropDown
        options={themes}
        value={themeId}
        onBlur={() => {}}
        onChange={async id => {
          setThemeId(id)

          const res = await global.fetch(`/theme/${id}`)
          const theme = await res.json()

          dispatch(setTheme(theme))
        }}
      >
        Change theme
      </DropDown>
      <Button
        sx={{ mt: 'sm' }}
        onClick={async () => {
          const res = await global.fetch(`/theme/${themeId}`)
          const theme = await res.json()

          dispatch(setTheme(theme))
        }}
      >
        Refresh
      </Button>
    </div>
  )
})

/** @jsx jsx */
import { jsx, Styled, useThemeUI } from 'theme-ui'
import { Fragment } from 'react'
import connect from '@h/connect-redux'
import { router, paginationLimit } from '@r/app-selectors'
import Pagination from '@e/pagination'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'
import { path } from '@u/obj'
import CategoryGuideCard from '@c/category-guide-card.connected'
import { Col, Row } from '@uswitch/trustyle.flex-grid'
import useMeta from '@h/use-meta'
import { sortByDate } from '@h/sort'

const Carousel = loadable(() => import('@m/collection-carousel'))

const Guides = ({
  className = '',
  variant,
  guides = [],
  layout = 'default',
  title,
  numberOfGuides,
  sys = {},
}) => {
  const { theme } = useThemeUI()
  const { total } = sys

  const metaVariant = useSelector(state => state.app.meta?.guideCard?.variant)
  const cardVariant = variant || metaVariant
  const meta = useMeta('guideCard')
  const limit = useSelector(paginationLimit)

  const [colsMobile, colsTablet, colsDesktop] = meta?.carouselColumns || [2, 2, 2]
  const itemsPerRow =
    path(['compounds', 'card', 'variants', cardVariant, 'itemsPerRow'], theme) ||
    path(['compounds', 'card', 'itemsPerRow'], theme)
  const isCarousel = layout === 'carousel'
  const isDefault = layout === 'default'

  const guideCardVariant = cardVariant

  // Filter faulty guides
  const { includes } = useSelector(state => state.contentful?.ctx)

  guides = guides
    .filter(guide => {
      if (guide?.fields?.variants?.length) return includes[guide.fields.variants[0].sys.id]
      else if (guide?.fields?.entry) return includes[guide.fields.entry.sys.id]
      else return includes[(guide.fields.link?.sys.id)]
    })
    .sort(sortByDate)

  const Wrapper = ({ children }) => (
    <Fragment>
      <Choose>
        <When condition={isCarousel}>
          <Carousel
            sys={sys}
            colsDesktop={colsDesktop}
            colsMobile={colsMobile}
            colsTablet={colsTablet}
          >
            {children}
          </Carousel>
        </When>
        <Otherwise>
          <Row cols={itemsPerRow} wrap>
            {children}
          </Row>
        </Otherwise>
      </Choose>
    </Fragment>
  )

  return (
    <Fragment>
      <If condition={title}>
        <Styled.h3
          sx={{
            variant: 'modules.categoryGuide.title',
            color: 'dark-2',
          }}
          className={className}
        >
          {title}
        </Styled.h3>
      </If>
      <Wrapper>
        <For index='key' each='url' of={guides}>
          <Col span={1} key={key} sx={{ variant: 'modules.categoryGuide.column' }}>
            <CategoryGuideCard guide={url} variant={guideCardVariant} />
          </Col>
        </For>
      </Wrapper>

      <If condition={total && isDefault}>
        <div sx={{ display: 'flex', justifyContent: 'center', my: 'sm' }}>
          <Pagination total={total} limit={Math.min(numberOfGuides, limit) || limit} />
        </div>
      </If>
    </Fragment>
  )
}

export default connect(s => router(s))(Guides)

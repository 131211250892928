import React, { Fragment, useEffect, useState } from 'react'
import { pagination } from '@r/app-selectors'
import { connect } from 'react-redux'
import Pagination from '@uswitch/trustyle.pagination'
import { Helmet } from 'react-helmet-async'
import useMeta from '@h/use-meta'
import { getQueryParam, setWindowURL } from '@h/url'
import { useResponsiveValue } from '@theme-ui/match-media'

const numberToLink = ({ limit, pathname, origin }) => number =>
  number === 1 ? `${pathname}` : `${pathname}?page=${number}`

export default connect(pagination)(props => {
  const { total, page, limit } = props
  const totalPages = Math.ceil(total / limit)
  const [currentPage, setCurrentPage] = useState(+page || 1)
  const meta = useMeta('pagination')
  const isMinimized = meta?.isMinimized ? useResponsiveValue(meta.isMinimized) : false
  const showFirstAndLastArrows = meta?.showFirstAndLastArrows ? meta.showFirstAndLastArrows : false

  useEffect(() => {
    const queryPage = getQueryParam('page')
    if (queryPage) setCurrentPage(+queryPage)
  }, [])

  const handleOnPageChange = (pageNumber, event) => setWindowURL(numberToLink(props)(pageNumber))

  return (
    <Fragment>
      <If condition={total > limit}>
        <Helmet>
          <link href={`${props.origin}${numberToLink(props)(currentPage)}`} rel='canonical' />
          <If condition={currentPage > 1}>
            <link rel='prev' href={`${numberToLink(props)(currentPage - 1)}`} />
            <link rel='first' href={`${numberToLink(props)(1)}`} />
          </If>
          <If condition={currentPage < totalPages}>
            <link rel='next' href={`${numberToLink(props)(currentPage + 1)}`} />
            <link rel='last' href={`${numberToLink(props)(totalPages)}`} />
          </If>
        </Helmet>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          numberToLink={numberToLink(props)}
          onPageChange={handleOnPageChange}
          minimized={isMinimized}
          showFirstAndLastArrows={showFirstAndLastArrows}
        />
      </If>
    </Fragment>
  )
})

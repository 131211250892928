/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import composeVariants from '@u/styled-variants'

export default ({ variant = 'primary', children, ...props }) => {
  const { theme } = useThemeUI()
  return (
    <button
      {...props}
      sx={{
        fontFamily: 'base',
        fontSize: 'base',
        paddingX: 'sm',
        paddingY: 'base',
        '&:hover': {
          textDecoration: 'underline',
        },
        textAlign: 'left',
        ...composeVariants([`buttons.${variant}`, 'elements.button'], theme),
      }}
    >
      {children}
    </button>
  )
}

import sw from '@u/switch'
import { contentTypeId } from '@u/contentful'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

const trimToLastWord = content => {
  const match = content.match(/[\\.\\!\\?]/gi)

  return match.length ? content.slice(0, content.lastIndexOf(match[match.length - 1]) + 1) : content
}

const trimRichPage = ({ guide, theme }) => {
  const { content } = guide
  const { maxCharacters } = theme.compounds?.card?.variants?.featured?.content

  return trimToLastWord(documentToPlainTextString(content).slice(0, maxCharacters))
}

const trimModularPage = ({ guide, theme }) => {
  const { modules } = guide
  const maxCharacters = theme.compounds?.card?.variants?.featured?.content?.maxCharacters || 256

  const content = modules
    .filter(module => contentTypeId(module).includes('richText'))
    .map(({ fields }) => documentToPlainTextString(fields.content))
    .join('\\n')

  return trimToLastWord(content.slice(0, maxCharacters))
}

const trimGuideContent = props => {
  return sw({
    richPage: trimRichPage,
    modularPage: trimModularPage,
  })(contentTypeId(props.guide), props)
}

export default trimGuideContent

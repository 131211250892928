const last = (a = []) => a[a.length - 1]
const withoutLast = (a = []) => a.slice(0, a.length - 1)
const oneLess = value => {
  if (value.endsWith('px')) return `${parseFloat(value) - 1}px`
  return value
}
const forceUnit = value => {
  if (Number(value) == value) return `${value}px`
  return value
}

/*
  const breakpoints = ["768px", "992px"]
  const widths = [ 279, 456, 523 ]
  sizes = `
    (max-width 767px) 279px,
    (max-width 992px) 456px,
    523px
  `

  const breakpoints = ["768px", "992px"]
  const widths = [ 279, 456 ]
  sizes = `
    (max-width 767px) 279px,
    456px
  `
*/

const getSizes = (widths, breakpoints) => {
  const sizes = withoutLast(widths)
    .map((w, i) => `(max-width: ${forceUnit(oneLess(breakpoints[i]))}) ${forceUnit(w)}`)
    .concat(forceUnit(last(widths)))
    .join(', ')
  return sizes
}

export default getSizes

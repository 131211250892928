/* eslint camelcase: ["error", {allow:["rate_table","^LEGACY__*","^UNSAFE_*"]}] */
import React from 'react'
import Card from '@c/card'
import connectEntity from '@h/connect-entity'
import { useSelector } from 'react-redux'
import { Icon } from '@uswitch/trustyle.icon'

import { contentTypeId } from '@u/contentful'
import sw from '@u/switch'
import dayjs from '@u/date'

/** --- Legacy start
 * These page types are legacy contentful modules
 * that new pages still want to render and link to
 */
const LEGACY__uswitch = ({ heading, hero_image: img, meta_description: body, url }) => ({
  title: heading,
  description: body,
  linkHref: url,
  linkText: 'More info',
  image: img,
})

const LEGACY__bankrate = ({
  description,
  meta_description: metaDescription,
  wp_updated_date: date,
  hero_image: image,
  title,
  url,
  linkText,
}) => ({
  title,
  image,
  linkHref: `/uk/${url}`,
  description: metaDescription || description,
  linkText: dayjs(date).format('Do MMMM, YYYY'),
})

/** --- Legacy end */

/**
 * Page Type mappings are based on the `URL' model in contentful
 * https://github.com/uswitch/contentful-components/tree/master/models/url
 */
const page = ({ pageType, ...props }) =>
  sw({
    Review: reviewPage,
    default: guidePage,
  })(pageType, props)

const reviewPage = ({
  showDescription,
  metaDescription,
  showDate,
  dateFormat,
  publishedAt,
  showLink,
  linkText,
  metaImage,
  title,
  url,
  ...props
}) => ({
  title,
  superScript: 'Review',
  description: showDescription ? metaDescription : null,
  linkText: showLink ? linkText : null,
  linkHref: url,
  image: metaImage,
})

const guidePage = ({
  showDescription,
  metaDescription,
  showDate,
  dateFormat,
  publishedAt,
  showLink,
  linkText,
  metaImage,
  title,
  url,
  ...props
}) => ({
  title,
  description: showDescription ? metaDescription : null,
  linkText: showLink ? linkText : null,
  linkHref: url,
  image: metaImage,
})

export const ConnectedGuide = connectEntity((state, props) =>
  sw({
    richPage: page,
    modularPage: page,
    guide: LEGACY__uswitch,
    post: LEGACY__bankrate,
    rate_table: LEGACY__bankrate,
    calculator: LEGACY__bankrate,
    default: null,
  })(contentTypeId(props), props),
)(Card)

export default ({ guide, linkText, ...props }) => {
  const connectedEntity = guide?.fields?.variants?.length
    ? guide?.fields?.variants[0]
    : guide?.fields?.entry || guide.fields.link
  const meta = useSelector(state => state.app.meta?.guideCard || {})

  /**
   * Meta settings to configure card display by brand
   *
   * showDescription - Whether to show the meta description in the card
   * showDate        - Whether to show the published date as superscript
   * showLink        - Whether to show the read more link text
   */
  const {
    linkText: linkTextValue = linkText,
    linkIcon = null,
    dateFormat = 'Do MMMM, YYYY',
    showDescription,
    showDate,
    showLink,
  } = { ...meta, ...meta[props.variant] }

  const headerIcon = meta[props.variant]?.headerIcon
  const headerIconSettings = (
    <If condition={headerIcon}>
      <Icon glyph={headerIcon.glyph} size={headerIcon.size} direction={headerIcon.direction} />
    </If>
  )

  return (
    <ConnectedGuide
      entity={connectedEntity}
      url={guide.fields.url}
      pageType={guide.fields.pageType}
      linkIcon={linkIcon}
      linkText={linkTextValue}
      dateFormat={dateFormat}
      showDescription={showDescription !== false}
      showDate={showDate !== false}
      showLink={showLink !== false}
      headerIconSettings={headerIconSettings}
      {...props}
    />
  )
}

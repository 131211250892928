import { css } from 'theme-ui'
import merge from 'deepmerge'

/**
 * Helper to combine multiple variants into relevant class names
 *
 * @param {string[][]} variants - The list of variants.
 * @param {Object} theme - The theme provided by theme ui.
 *
 * @returns {string} A string of class names
 */
export default (variants = [], theme) =>
  variants.reduce((acc, variant) => merge(acc, css({ variant })(theme)), {})

import { useSelector } from 'react-redux'

const useMeta = (component, variant, defaultValue = {}) =>
  useSelector(
    state =>
      (variant ? state?.app?.meta?.[component]?.[variant] : state?.app?.meta?.[component]) ||
      defaultValue,
  )

export default useMeta

/** @jsx jsx */
import { jsx, Styled, useThemeUI } from 'theme-ui'
import { useSelector } from 'react-redux'
import connectEntity from '@h/connect-entity'
import { ConnectedGuide } from '@c/category-guide-card.connected'
import { Col, Container, Row } from '@uswitch/trustyle.flex-grid'

const ConnectedGuideCard = connectEntity()(({ link, url, variant = 'readMoreCard' }) => {
  const meta = useSelector(state => state.app.meta?.guideCard || {})
  const { showDescription, showDate, dateFormat, showLink, linkText } = meta

  return (
    <ConnectedGuide
      showDescription={showDescription}
      showDate={showDate}
      showLink={showLink}
      dateFormat={dateFormat}
      linkText={linkText}
      variant={variant}
      entity={link}
      url={url}
    />
  )
})

export default ({ heading, linkedUrls }) => {
  const { theme } = useThemeUI()

  return (
    <div
      sx={{
        width: '100%',
        backgroundColor: 'light-2',
      }}
    >
      <Container sx={{ py: 'lg' }}>
        <If condition={heading}>
          <Styled.h3 sx={{ color: 'dark-2' }}>{heading}</Styled.h3>
        </If>

        <Row cols={theme?.compounds?.card?.itemsPerRow} wrap>
          <For index='key' each='url' of={linkedUrls}>
            <Col span={1} key={key}>
              <ConnectedGuideCard entity={url} />
            </Col>
          </For>
        </Row>
      </Container>
    </div>
  )
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { cf2imgix } from '@h/imgix-image'
import Card from '@uswitch/trustyle.card'
import { Palette } from '@uswitch/trustyle-utils.palette'
import { ModulesToReactComponents } from '@h/modules'
import { useSelector } from 'react-redux'
import { useEntity } from '@h/use-entity'
import trimGuideContent from '@h/trim-guide-content'

import { path } from '@u/obj'
import getSizes from '@u/get-sizes'

const DEFAULT_CONTAINER_MAX_WIDTH = 1200

export const BlankCard = () => (
  <div
    sx={{
      display: 'flex',
      width: ({ compounds = {} }) => (compounds.card ? compounds.card.width : 0),
    }}
  />
)
const CardWithConnectedImage = ({ entity, cardProps }) => {
  const { description, date, file } = useEntity(entity)
  const meta = useSelector(state => state?.app?.meta?.card)

  const props = {
    critical: false,
    imgSrc: cf2imgix(file?.url),
    imgAlt: description,
    superScript: date,
    ...cardProps,
  }

  if (meta?.clickable && props.linkHref) {
    return (
      <div
        onClick={() => (window.location.href = props.linkHref)}
        sx={{ cursor: 'pointer', variant: 'compounds.card.clickWrapper' }}
        role='link'
        tabIndex={0}
      >
        <Card {...props} />
      </div>
    )
  }
  return <Card {...props} />
}

// We are connecting the nested image asset to the card data
const WrappedCard = props => {
  const { entity, variant } = props
  const theme = useThemeUI().theme

  const meta = useSelector(state => path(['app', 'meta', 'card'], state, {}))
  const includes = useSelector(state => path(['contentful', 'ctx', 'includes'], state))

  const customVariant = path(['variants', variant, 'custom'], meta, false)
  const guide = useEntity(entity)

  const ids = guide?.modules?.map(({ sys }) => sys.id) || []
  const modules = ids.length
    ? Object.entries(includes)
        .filter(([id, value]) => ids.includes(id))
        .map(([id, value]) => value)
    : []

  const cardTheme = theme.compounds?.card?.variants?.[props.variant] || {}
  const mappedProps = {
    imgSizes: cardTheme?.imgSizes,
  }

  if (props.headerIconSettings) mappedProps.headerChildren = props.headerIconSettings
  if (props.headerChildren)
    mappedProps.headerChildren = ModulesToReactComponents(props.headerChildren?.content)
  if (props.contentChildren)
    mappedProps.contentChildren = ModulesToReactComponents(props.contentChildren?.content)
  if (cardTheme?.imageSize) mappedProps.imageSize = cardTheme?.imageSize

  const metaImageProps = meta?.variants?.[variant]?.imageProps || {}
  const imageProps = {
    ...metaImageProps,
    imgixParams: {
      auto: 'format,compress',
      ...metaImageProps.imgixParams,
    },
    sizes: getSizes(
      [
        '100vw',
        '100vw',
        path(['layout', 'flex-grid', 'container', 'maxWidth'], theme, DEFAULT_CONTAINER_MAX_WIDTH),
      ],
      theme.breakpoints,
    ),
  }

  const description =
    customVariant &&
    guide.sys &&
    trimGuideContent({
      guide: { ...guide, modules },
      theme,
    })

  const linkIconValue = meta?.variants?.[variant]?.linkIcon || null

  return (
    <Choose>
      <When condition={props.image}>
        <CardWithConnectedImage
          entity={props.image}
          cardProps={{
            ...props,
            ...mappedProps,
            linkIcon: linkIconValue,
            imageProps,
            ...(customVariant && guide.sys && { description }),
          }}
        />
      </When>
      <Otherwise>
        <Card {...props} />
      </Otherwise>
    </Choose>
  )
}

export default props => (
  <Palette
    px={{
      backgroundColor: 'featureColor',
      p: {
        color: 'textColor',
      },
      'div > h3, div > h3 > a': {
        color: 'accentTextColor',
      },
    }}
    as={WrappedCard}
    {...props}
  />
)
